@import url('https://rsms.me/inter/inter.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

*, ::before, ::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter var', sans-serif;
  font-feature-settings: 'ss03', 'liga', 'tnum', 'zero', 'cv06', 'cv08',
  'cv10', 'cv11' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  75%, 100% {
    transform: scale(1.3, 1.5);
    opacity: 0;
  }
}


.pop-open {
  animation: pop-open 0.1s both;
}


@keyframes pop-open {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }

  75% {
    transform: scale(1.05);
    opacity: 1;
  }

  100%{
    transform: scale(1);
  }
}


