* {
    --green-button: 52, 157, 143;
}

.green-button-shimmer {
    background-color: rgba(var(--green-button), 0.6);
}
.green-button-solid {
    background-color: rgba(var(--green-button), 1);
}

.green-button:hover {
    background-color: rgba(var(--green-button), 0.5);
}